<template>
<div>
        <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
       <v-toolbar flat dense class="tc-title">
             <router-link to="student-personalDetails" class="nav-arrow-left"><v-icon class="left-arrow" >mdi-arrow-left-bold</v-icon></router-link>
            <v-toolbar-title>Address Details</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
             <router-link to="student-academics" class="nav-arrow-right"><v-icon class="right-arrow">mdi-forward</v-icon></router-link>
         </v-toolbar>
       <v-card>
           <v-card-text>
               <v-row >
                   <v-col cols="12" sm="5">
                        <label>Local Address<span class="required">*</span> :</label>
                        <v-textarea 
                            outlined
                            v-model="local.address"
                            name="input-7-4"
                            value="local_Add.address"
                            :rules="[ruless, rules.isEmpty]"
                      onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode >=48 && event.keyCode <= 57) || event.charCode == 44 || event.charCode == 38 || event.charCode == 32">
                            </v-textarea>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <label>Pin Code<span class="required" counter="6">*</span> :</label>
                                <v-text-field type="number" hint="Only Enter Number Value"  :value="0" min="0" v-model="local.pin" :rules="[v => !!v || 'required'||/^-?(0|[1-9]\d*)?$/]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                            <label>Select Country<span class="required">*</span> :</label>
                                <v-select
                                :items="countryList"
                                v-model="local.cid"
                                :rules="[v => !!v || 'required']"
                                item-text="country"
                                item-value="id"
                                required
                                ></v-select>     
                            </v-col>
                        </v-row>
                   </v-col>
                   <v-col cols="12" sm="2">
                       <!-- <v-checkbox
                        label="Whether Permanent Address is same as Local Address"
                        required
                        v-model="checkbox"
                        @click="copyadd()"
                        ></v-checkbox> -->
                        <h4><input type="checkbox"
                      v-model="checkbox"
                      color="success" style="margin: 0.4rem; width:18px; height: 15px;" @click="copyadd()"
                      />&nbsp;&nbsp;Whether Permanent Address is same as Local Address</h4>
                   </v-col>
                   <v-col cols="12" sm="5">
                        <label>Permanent Address<span class="required">*</span> :</label>
                        <v-textarea 
                            outlined
                            :rules="[ruless, rules.isEmpty]"
                      onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode >=48 && event.keyCode <= 57) || event.charCode == 44 || event.charCode == 38 || event.charCode == 32"
                            v-model="permenant.address"
                            name="input-7-4"
                            value="local_Add.address">
                            </v-textarea>
                        <v-row>
                        <v-col cols="12" sm="6">
                            <label>Pin Code<span class="required">*</span> :</label>
                            <v-text-field type="number" hint="Only Enter Number Value"  :value="0" min="0" v-model="permenant.pin" :rules="[v => !!v || 'required']"></v-text-field>
                        </v-col>
                            <v-col cols="12" sm="6">
                                <label>Select Country<span class="required">*</span> :</label>

                                <v-select
                                :items="countryList"
                                v-model="permenant.cid"
                                item-text="country"
                                item-value="id"
                                :rules="[v => !!v || 'required']"
                                ></v-select>     
                            </v-col>
                        </v-row>
                   </v-col>
               </v-row>
               <v-row>
                   <v-col cols="12" sm="12"  align="center">
                       <v-btn color="primary darken-1" dark @click="save()">Save</v-btn>
                   </v-col>
               </v-row>
           </v-card-text>
       </v-card>
</div>
</template>
<script>
import StudentProfile from './StudentProfile'
import axios from 'axios'
    export default {
        components:{
            StudentProfile
        },
        data:()=> ({
            ruless: [
        (value) => {
          if (!value) {
            return [];
          } else if (/^\d+$/.test(value)) {
            return "Numeric values are not allowed";
          } else {
            return true;
          }
        },
      ],
      rules: {
        isEmpty: (value) => !!value || 'Field is required',
      },
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: '',
            countryList:'',
             local:{
                address:null,
                pin:null,
                cid:null,
                l_id:null
            },
            
            permenant:{
                address:null,
                pin:null,
                cid:null,
                p_id:null
            },
            checkbox:false,
            savedata:[],

    //           pincode: [
    //   (v) => !!v || "pincode is required",
    //   (v) =>  /^-?(0|[1-9]\d*)?$/ || "pincode must be valid",
    //    [v => (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) || 'Number has to be between 0 and 999']
    // ],
    pincode: [
      (v) => !!v || "pincode No is required",
      (v) => (v && v.length == 6) || "Number must be  6 digits",
    ],
            
        }),
        mounted() {
            this.onLoad()
        },
        methods: {
            copyadd()
            {
                if(this.checkbox==false){
                    this.permenant.address=this.local.address
                this.permenant.pin=this.local.pin
                this.permenant.cid=this.local.cid
                }else{
                    this.permenant.address="";
                this.permenant.pin="";
                this.permenant.cid="";
                }
                
            },
            onLoad(){
                    axios
                        .post("/LearnerData/learnerAddress")
                        .then(res => {
                            if (res.data.msg == "200") {
                                    this.countryList=res.data.country_List

                                    this.local.address=res.data.local_address
                                    this.local.pin=res.data.local_pin
                                    this.local.cid=res.data.local_country_id
                                    this.local.l_id=res.data.local_id
                                    this.permenant.address=res.data.permanent_address
                                    this.permenant.pin=res.data.permanent_pin
                                    this.permenant.cid=res.data.permanent_country_id
                                    this.permenant.p_id=res.data.permanent_id
                            } else {
                                this.showSnackbar("#b71c1c", "No Data");
                                //window.console.log(res.data.msg)
                            }
                        })
                        .catch(error => {
                            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                            //window.console.log(error);
                        })
                        .finally(() => {
                            // 'use strict';
                        });
                },
            showSnackbar(clr, msg) {
                    this.snackbar = true;
                    this.color = clr;
                    this.snackbar_msg = msg;
                },
                          
            close() {
                    this.dialog = false
                    setTimeout(() => {
                        this.editedItem = Object.assign({}, this.defaultItem)
                        this.editedIndex = -1
                    }, 300)
                },
                hasWhiteSpace(s) {
      var status = false;
      var reWhiteSpace = new RegExp(/^\s+$/);

      // Check for white space
      if (reWhiteSpace.test(s)) {
        alert("Please Check Your Fields For Spaces");
        //   return true;
        return /^\s/.test(s);
      }
      //   return false;
      return /^\s/.test(s);
    },
            save() {
                
                if(!this.local.address || /^[ \d]+$/.test(this.local.address) || this.hasWhiteSpace(this.local.address))
                {
                    this.showSnackbar("#b71c1c", "Enter Local Address...");
                    return
                }
                else if(this.local.pin==null)
                {
                    this.showSnackbar("#b71c1c", "Enter Local Address Pincode...");
                }
                 else if(this.local.pin.length!=6)
                {
                    this.showSnackbar("#b71c1c", " Local Pincode Must Be 6 Digits Only...");
                }
                else if(this.local.cid==null)
                {
                    this.showSnackbar("#b71c1c", "Select Local Address Country...");
                }
                else if(!this.permenant.address || /^[ \d]+$/.test(this.permenant.address) || this.hasWhiteSpace(this.permenant.address))
                {
                    this.showSnackbar("#b71c1c", "Enter Permanent Address...");
                    return
                }
                else if(this.permenant.pin==null)
                {
                    this.showSnackbar("#b71c1c", "Enter Permanent Address  Pincode ...");
                }
                 else if(this.permenant.pin.length!=6)
                {
                    this.showSnackbar("#b71c1c", " Permanent Pincode must be 6 Digits Only ...");
                }
                else if(this.permenant.cid==null)
                {
                    this.showSnackbar("#b71c1c", "Select Permenant Address Country...");
                }
                else
                {
                   this.savedata.push(this.local)
                   this.savedata.push(this.permenant) 
                   
                    axios
                        .post("/LearnerData/saveAddress", this.savedata)
                        .then(res => {
                            if (res.data.msg == "200") {
                                this.onLoad()
                                this.showSnackbar("#4caf50", "Address Save Successfully..."); // show snackbar on success
                            }
                            else{
                                this.showSnackbar("#b71c1c", "Something went wrong...");
                            }
                        })
                        .catch(error => {
                            //window.console.log(error);
                        });
                    this.close();
            }
            },// end of Save()
        },
    }
    
</script>
<style scoped>
.nav-arrow-left{
        margin-right: 5px;
        text-decoration: none;
        color: #FFF;
    }
    .nav-arrow-right{
        margin: 5px;
        text-decoration: none;
        color: rgb(3, 55, 167);
    }
    .left-arrow{
        color: #FFF;
    }
    .right-arrow{
        color: rgb(3, 55, 167);
    }
.ec-title{
        background-image: linear-gradient(-100deg, #f4faba, #d64907);
        color: #FFF;
    }
    .c-title{
        background-image: linear-gradient(-90deg, #befcf7, #03948d);
        color: #FFF;
    }
    .tc-title{
        background-image: linear-gradient(-90deg, #FFF, #057996);
        color: #FFF;
        border-radius: 3px;
    }
    .btn-title{
        background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
        color: #FFF;
    }
    .card-title{
         background-image: linear-gradient(-90deg, #8edffd, #034c70);
        color: #FFF;
    }
    .del-card-title{
         background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
        color: #FFF;
    }
    .lbl{
        color: #1705e6;
    }
     .required{
  color:red;
  font-size:14px;
  font-style:bold;
  }
</style>